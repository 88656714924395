import React from 'react';

const TermsAndConditionsPage = () => {
  return (
    <div className='container'>
      <br />
      <h1>Termos de Uso</h1>
      <ol>
        <li>
          <h2>Aceitação dos Termos</h2>
          <p>
            Ao acessar e utilizar meucarroja.com, você concorda em cumprir os
            termos e condições estabelecidos neste documento. Caso não concorde
            com algum dos termos aqui apresentados, pedimos que não utilize o
            nosso serviço.
          </p>
        </li>
        <li>
          <h2>Informações Geradas por Terceiros</h2>
          <p>
            As informações contidas em meucarroja.com são fornecidas e mantidas
            por fontes externas. Não somos responsáveis pela criação ou
            autenticidade dos dados. Nosso papel é unicamente de organizar e
            agrupar estas informações para facilitar o acesso e a utilização por
            parte dos usuários.
          </p>
        </li>
        <li>
          <h2>Isenção de Responsabilidade</h2>
          <p>
            Não garantimos a precisão, a completude, ou a utilidade das
            informações apresentadas. Dependemos de fontes terceiras para a
            coleta dos dados, e, portanto, não podemos assegurar a integridade
            ou precisão das mesmas. Não nos responsabilizamos por quaisquer
            erros, omissões ou representações nas informações fornecidas.
          </p>
        </li>
        <li>
          <h2>Modificações dos Termos</h2>
          <p>
            Reservamo-nos o direito de modificar estes termos a qualquer
            momento. As alterações entrarão em vigor imediatamente após a
            publicação na mesma página.
          </p>
        </li>
        <li>
          <h2>Contato</h2>
          <p>
            Caso tenha dúvidas ou necessite de mais informações sobre os nossos
            termos de uso, por favor, entre em contato através de
            contato@meucarroja.com.
          </p>
        </li>
      </ol>
      <br />
    </div>
  );
};

export default TermsAndConditionsPage;
