import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { RiMenu3Line, RiMenuFill } from 'react-icons/ri';
import './Header.css';

const NavbarItems = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Buscar Veículos',
    url: '/veiculos',
  },
  {
    label: 'Meus preferidos',
    url: '/favoritos',
  },
  {
    label: 'Termos de uso',
    url: '/termos-de-uso',
  },
  {
    label: 'Contato',
    url: '/contato',
  },
];

type HeaderProps = {
  showLogo?: boolean;
};

const Header = ({ showLogo = true }: HeaderProps) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();

  const onSandwichClickHandler = () => {
    setShowNavbar((prevShowNavbar) => !prevShowNavbar);
  };

  const onLinkClickHandler = () => {
    setShowNavbar(false);
  };

  return (
    <header>
      <div className='container'>
        <ul>
          <li style={{ marginRight: 'auto' }}>
            <Link
              to='/'
              onClick={onLinkClickHandler}
              style={{ opacity: showLogo || showNavbar ? '100%' : '0%' }}
            >
              <img src='/img/meucarroja-bg-white.png' alt='Meu Carro Já' />
            </Link>
          </li>

          <li className='nav-icon' onClick={onSandwichClickHandler}>
            {showNavbar ? <RiMenu3Line /> : <RiMenuFill />}
          </li>
          {showNavbar && (
            <>
              {NavbarItems.map((item, i) => (
                <li
                  className={`nav-mobile ${location.pathname === item.url ? 'active' : ''}`}
                  key={i}
                >
                  <Link to={item.url} onClick={onLinkClickHandler}>
                    {item.label}
                  </Link>
                </li>
              ))}
            </>
          )}
          {NavbarItems.map((item, i) => (
            <li
              className={`nav-desktop ${location.pathname === item.url ? 'active' : ''}`}
              key={i}
            >
              <Link to={item.url}>{item.label}</Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Header;
