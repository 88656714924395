import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer>
        <img src='/img/meucarroja-bg-white.png' alt='Meu Carro Já' />
        <ul>
          <li>
            <Link className='footer-link' to={'/home'}>
              Home
            </Link>
          </li>
          <li>
            <Link className='footer-link' to={'/veiculos'}>
              Buscar veículos
            </Link>
          </li>
          <li>
            <Link className='footer-link' to={'/termos-de-uso'}>
              Termos de uso
            </Link>
          </li>
          <li>
            <Link className='footer-link' to={'/contato'}>
              Contato
            </Link>
          </li>
        </ul>
        <div>
          <h5>www.meucarroja.com</h5>
          <h6>contato@meucarroja.com</h6>
        </div>
      </footer>
    </>
  );
};

export default Footer;
