import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Car } from "../../types/Veiculo";
import { useLocation } from "react-router-dom";
import { searchAllVehicles } from "../../api/api.thunk";

export type VehiclesContext = {
    onSubmitSearch: (term: string) => void
    vehicles: Car[]
    isLoading: boolean
    showLoadMore: () => boolean
    onLoadMore: () => void
    triggerSearch: () => void
}

const VeichlesContext = createContext<VehiclesContext | null>(null);

export const VehiclesProvider = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [vehicles, setVehicles] = useState<Car[]>([]);

    const [lastSearchTerm, setLastSearchTerm] = useState<string | null>(null);
    const [lastPage, setLastPage] = useState<number | null>(null);

    const fetchData = async ({
        term,
        page,
    }: {
        term: string;
        page: number;
    }) => {
        console.log({ term, searchTerm })
        if (term === searchTerm && page === lastPage) return
        try {
            console.log('currentPage', currentPage)
            setIsLoading(true);
            const newData = await searchAllVehicles({ searchTerm: term, currentPage: page });
            setVehicles((vehicles) => {
                return page === 1 ? newData : [...vehicles, ...newData];
            });
            setSearchTerm(term);
            setLastSearchTerm(term)
            setCurrentPage(page)
            setLastPage(page)
            updateSearchTermInURL(term);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const showLoadMore = () => {
        if (vehicles.length === 0) {
            return false;
        } else {
            return vehicles.length % 30 === 0;
        }
    };

    const onLoadMore = async () => {
        await fetchData({
            page: currentPage + 1,
            term: searchTerm,
        });
    };

    const onSubmitSearch = (term: string) => {
        setSearchTerm(term);
        setCurrentPage(1);
        fetchData({ term, page: 1 });
    };


    const updateSearchTermInURL = (newSearchTerm: string) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('termo', newSearchTerm);

        const newURL = `${location.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, '', newURL);
    };

    const triggerSearch = () => {
        const queryParams = new URLSearchParams(location.search);
        const queryTerm = queryParams.get('termo') || '';
        console.log('xxxxx', queryTerm, lastSearchTerm, currentPage, lastPage)
        if (queryTerm !== lastSearchTerm || currentPage != lastPage) {
            console.log('xxxxx executou?')
            setCurrentPage(1);
            fetchData({ term: queryTerm, page: 1 });
        }
    }

    return (
        <VeichlesContext.Provider value={{
            onSubmitSearch, vehicles, isLoading, showLoadMore, onLoadMore, triggerSearch
        }}>
            {children}
        </VeichlesContext.Provider>

    )
}

export const useVehicles = () => useContext(VeichlesContext)