import React from 'react';
import { Car } from '../../types/Veiculo';
import Card from '../../components/card/Card';
import './FavoritesPage.css';

const FavoritesPage = () => {
  const favoriteCars: Car[] = JSON.parse(
    localStorage.getItem('favoriteCars') || '[]'
  );

  return (
    <div className='container'>
      <h4>Exibindo meus preferidos</h4>
      <div className='favorites-container'>
        {favoriteCars.length > 0 ? (
          favoriteCars
            .filter((car) => car)
            .map((car) => <Card key={car?.slug} car={car} />)
        ) : (
          <p>Você ainda não adicionou nenhum favorito.</p>
        )}
      </div>
    </div>
  );
};

export default FavoritesPage;
