import React from 'react';
import './SecondSection.css';
import { AiFillRightCircle } from 'react-icons/ai';
import {
  FcApproval,
  FcBinoculars,
  FcOvertime,
  FcReading,
} from 'react-icons/fc';

const SecondSection = () => {
  return (
    <div className='container bullet-list'>
      <h2>Variedade. Agilidade. Qualidade.</h2>
      <h4>A melhor experiência na busca pelo seu próximo veículo.</h4>
      <ul className='benefits-list'>
        <li>
          <div className='bullet-icon'>
            <FcReading />
          </div>
          <h4>Variedade de Opções</h4>
          <p>Mais de 4.000 veículos disponíveis na região Sul.</p>
        </li>
        <li>
          <div className='bullet-icon'>
            <FcBinoculars />
          </div>
          <h4>Busca Personalizada</h4>
          <p>Encontramos veículos próximos a você.</p>
        </li>
        <li>
          <div className='bullet-icon'>
            <FcOvertime />
          </div>
          <h4>Economia de Tempo</h4>
          <p>Encontre o carro dos seus sonhos rapidamente.</p>
        </li>
        <li>
          <div className='bullet-icon'>
            <FcApproval />
          </div>
          <h4>Ofertas Exclusivas</h4>
          <p>Acesse ofertas especiais e veículos de qualidade.</p>
        </li>
      </ul>
    </div>
  );
};

export default SecondSection;
