import React, { useEffect } from 'react';
import './SearchPage.css';
import InputSearch from '../../../components/InputSearch/InputSearch';
import Card from '../../../components/card/Card';
import Loader from '../../../components/Loader/Loader';
import { MdOutlineDownloading } from 'react-icons/md';
import { useVehicles, VehiclesContext } from '../Vehicles.context';

const SearchPage = () => {
  const { onSubmitSearch, vehicles, isLoading, showLoadMore, onLoadMore, triggerSearch } = useVehicles() as VehiclesContext

  useEffect(() => {
    triggerSearch()
  }, [])

  return (
    <>
      <div className='container'>
        <h4>Exibindo resultados</h4>
        <InputSearch onSubmitHandler={onSubmitSearch} />
        <div className='search-container'>
          {vehicles.map((vehicle, index) => (
            <Card car={vehicle} key={index} />
          ))}
        </div>
        {isLoading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}
        {showLoadMore() && (
          <div className='vermais-container flex-center'>
            <button className='button button-secondary button-big' onClick={onLoadMore}>
              {isLoading && <Loader />}
              {!isLoading && <MdOutlineDownloading size='18px' />}
              Ver Mais
            </button>
          </div>
        )}
      </div>
      {vehicles.length > 0 && <div className='counter'>Exibindo {vehicles.length} resultados...</div>}
    </>
  );
};

export default SearchPage;
