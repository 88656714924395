import React from 'react';
import './Loader.css';
import { RiLoaderFill } from 'react-icons/ri';

const Loader = () => {
  return (
    <>
      <RiLoaderFill className='loader' />
    </>
  );
};

export default Loader;
