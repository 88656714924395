import React, { useEffect, useState } from 'react';
import './InputSearch.css';
import { FaSearch } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

type InputSearchProps = {
  onSubmitHandler: (searchTerm: string) => void;
};

const InputSearch = ({ onSubmitHandler }: InputSearchProps) => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmitHandler(searchTerm);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get('termo') || '';
    setSearchTerm(searchTerm);
  }, [location.search]);

  return (
    <>
      <form className='hero-input-container' onSubmit={onSubmit}>
        <FaSearch className='hero-input-icon' />
        <input
          type='text'
          name='searchTerm'
          placeholder='Pesquise por nome, modelo ou marca'
          value={searchTerm}
          onChange={handleSearchChange}
          className='hero-search-input'
        />
        <button className='input-button' onClick={onSubmit}>
          <FaSearch />
        </button>
      </form>
    </>
  );
};

export default InputSearch;
