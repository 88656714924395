import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Car } from '../../types/Veiculo';
import HeroSearch from '../../components/HeroSearch/HeroSearch';
import Card from '../../components/card/Card';
import './HomePage.css';
import useGeolocation from '../../utils/useGeolocation';
import { calculateDistance } from '../../utils/calculateDistance';
import Tile, { TileList } from '../../components/Tile/Tile';
import { AiFillRightCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import FirstSection from './components/FirstSection/FirstSection';
import SecondSection from './components/SecondSection/SecondSection';
import Loader from '../../components/Loader/Loader';
import { getApiStatus, StatusResponseType } from '../../api/api.thunk';
import Tile2 from '../../components/Tile2/Tile2';

const apiUrl = process.env.REACT_APP_API_URL;

const HomePage = () => {
  const loader = useRef(null);
  const [veiculos, setVeiculos] = useState<Car[]>([]);
  const [tiles, setTiles] = useState(TileList);
  const [apiStatus, setApiStatus] = useState<StatusResponseType | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const userLocation = useGeolocation();
  const navigate = useNavigate();

  const fetchData = useCallback(
    async (page: number, searchTerm: string) => {
      try {
        const pageSize = 6;
        setCurrentSearchTerm(searchTerm);
        if (isLoading) return;
        setIsLoading(true);
        const url = `${apiUrl}/veiculos?pageSize=${pageSize}&page=${page}&search=${searchTerm}`;
        const response = await fetch(url);
        const newData: Car[] = await response.json();
        if (page === 1) {
          setVeiculos(newData);
        } else {
          setVeiculos((prevVeiculos) => [...prevVeiculos, ...newData]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [isLoading]
  );

  const handleSearchSubmit = (searchTerm: string) => {
    redirectToSearchPage(searchTerm);
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading) {
          setCurrentPage((currentPage) => currentPage + 1);
          fetchData(currentPage + 1, currentSearchTerm);
        }
      },
      { threshold: 0.5 }
    );

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => observer.disconnect();
  }, [fetchData, isLoading]);

  const getDistanceKM = () => {
    if (userLocation.latitude != 0 && userLocation.longitude != 0) {
      const _veiculos = veiculos.map((vehicle) => {
        const distance = calculateDistance(
          userLocation.latitude as number,
          userLocation.longitude as number,
          vehicle.shop.coordinates.latitude,
          vehicle.shop.coordinates.longitude
        );
        return { ...vehicle, distanceKm: Math.round(distance) };
      });
      setVeiculos(_veiculos);
    }
  };

  useEffect(() => {
    if (veiculos.length) getDistanceKM();
  }, [userLocation.latitude, veiculos.length]);

  const onTileClick = (value: string) => {
    redirectToSearchPage(value);
  };

  const redirectToSearchPage = (term: string) => {
    navigate(`/veiculos?termo=${term}`);
  };

  const fetchApiStatus = async () => {
    if (!!apiStatus) return;
    const data = await getApiStatus();
    setApiStatus(data);
    setTiles((prevTiles) =>
      prevTiles.map((tile) => ({
        ...tile,
        total: data[tile.header.toLowerCase() as keyof StatusResponseType] || 0,
      }))
    );
  };

  useEffect(() => {
    fetchApiStatus();
  }, []);

  return (
    <>
      <HeroSearch onSubmitHandler={handleSearchSubmit} />
      <div className='container'>
        <h2>Os preferidos</h2>
        <Tile2 civic={apiStatus?.civic} corolla={apiStatus?.corolla} hilux={apiStatus?.hilux} compass={apiStatus?.compass} />
      </div>
      {/* <div className='container' style={{ marginTop: '64px' }}>
        <h4>Mais queridos</h4>
      </div>
      <Tile onClick={onTileClick} tileList={tiles} /> */}
      <div style={{ marginBottom: '64px' }}></div>
      <div className='container'>
        <h4>Novos Registros</h4>
        <div className='veiculos-container'>
          {veiculos.map((veiculo, index) => (
            <Card car={veiculo} key={index} />
          ))}
          {!isLoading && veiculos.length === 0 && (
            <div className='oops'>
              Oops! Não encontramos nenhum resultado, por favor refine novamente
              sua pesquisa...
            </div>
          )}
          {isLoading && (
            <div className='loader-container'>
              <Loader />
            </div>
          )}
        </div>
      </div>
      <FirstSection />
      <SecondSection />
    </>
  );
};

export default HomePage;
