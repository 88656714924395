import React from 'react';
import { MdArrowOutward } from 'react-icons/md';
import './Tile2.css';
import { useNavigate } from 'react-router-dom';

type Tile2Type = {
  civic?: number;
  corolla?: number;
  hilux?: number;
  compass?: number;
};

const Tile2 = ({
  civic = 0,
  corolla = 0,
  hilux = 0,
  compass = 0,
}: Tile2Type) => {
  const navigate = useNavigate();

  const onButtonClick = (url: string) => {
    navigate(`/veiculos?termo=${url}`);
  };

  return (
    <>
      <div className='tiletwo-container'>
        <div
          className='tiletwo-box tiletwo-img-one'
          onClick={() => onButtonClick('civic')}
        >
          <div className='tiletwo-content'>
            <h4>Civic</h4>
            {!!civic && <p>{civic} Veículos</p>}
          </div>
          <button className='tiletwo-button'>
            <MdArrowOutward />
          </button>
        </div>
        <div
          className='tiletwo-box tiletwo-img-two'
          onClick={() => onButtonClick('corolla')}
        >
          <div className='tiletwo-content'>
            <h4>Corolla</h4>
            {!!corolla && <p>{corolla} Veículos</p>}
          </div>
          <button className='tiletwo-button'>
            <MdArrowOutward />
          </button>
        </div>
        <div
          className='tiletwo-box tiletwo-img-three'
          onClick={() => onButtonClick('hilux')}
        >
          <div className='tiletwo-content'>
            <h4>Hilux</h4>
            {!!hilux && <p>{hilux} Veículos</p>}
          </div>
          <button className='tiletwo-button'>
            <MdArrowOutward />
          </button>
        </div>

        <div
          className='tiletwo-box tiletwo-img-five'
          onClick={() => onButtonClick('compass')}
        >
          <div className='tiletwo-content'>
            <h4>Compass</h4>
            {!!compass && <p>{compass} Veículos</p>}
          </div>
          <button className='tiletwo-button'>
            <MdArrowOutward />
          </button>
        </div>
      </div>
    </>
  );
};

export default Tile2;
