import React from 'react'
import { Route, Routes } from 'react-router-dom'
import VehicleDetailsPage from './VehicleDetails/VehicleDetails'
import SearchPage from './Search/SearchPage'

const Vehicles = () => {

    return (
        <>
            <Routes>
                <Route path="/" element={<SearchPage />} />
                <Route path="/detalhes/:slug" element={<VehicleDetailsPage />} />
            </Routes>
        </>
    )
}

export default Vehicles