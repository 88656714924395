import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import './Tile.css';
import { getApiStatus, StatusResponseType } from '../../api/api.thunk';

export const TileList = [
  {
    img: '/img/corolla.png',
    header: 'Corolla',
    total: 0,
    position: {
      height: '100%',
      top: '-23px',
      right: '-80%',
    },
  },
  {
    img: '/img/hilux.png',
    header: 'Hilux',
    total: 0,
    position: {
      height: '100%',
      top: '-23px',
      right: '-77px',
    },
  },
  {
    img: '/img/compass.png',
    header: 'Compass',
    total: 0,
    position: {
      height: '61%',
      top: '2px',
      right: '-39px',
    },
  },
  {
    img: '/img/polo.png',
    header: 'Polo',
    total: 0,
    position: {
      height: '63%',
      top: '4px',
      right: '-48px',
    },
  },
  {
    img: '/img/civic-g10.png',
    header: 'Civic',
    total: 0,
    position: {
      height: '67%',
      top: '0px',
      right: '-38px',
    },
  },
];

type TileObject = {
  img: string;
  header: string;
  total: number;
  position: {
    height: string;
    top: string;
    right: string;
  };
};

type TileProps = {
  onClick: (value: string) => void;
  tileList: TileObject[];
};

const Tile = ({ onClick, tileList }: TileProps) => {
  const onClickHander = (value: string) => {
    onClick(value);
  };

  // const fetchData = async () => {
  //   const data = await getApiStatus();
  //   setTiles((prevTiles) =>
  //     prevTiles.map((tile) => ({
  //       ...tile,
  //       total: data[tile.header.toLowerCase() as keyof StatusResponseType] || 0,
  //     }))
  //   );
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <>
      <div className='tile-container'>
        {tileList.map((tile, index) => {
          const { height, top, right } = tile.position;
          return (
            <div
              className='tile-box'
              key={index}
              onClick={() => onClickHander(tile.header)}
            >
              <img
                className='tile-image'
                src={tile.img}
                alt={tile.header}
                style={{
                  height,
                  top,
                  right,
                }}
              />
              <div className='tile-content'>
                <h4 className='tile-header'>{tile.header}</h4>
                {!!tile.total && (
                  <p className='tile-text'>+{tile.total} veículos</p>
                )}
                <div className='tile-button'>
                  <FaArrowRight size='11px' />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Tile;
