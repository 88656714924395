// src/pages/ContatoPage.tsx
import React, { useState } from 'react';
import './ContatoPage.css';
import { sendMessage } from '../../api/api.thunk';

const ContatoPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = { name: '', email: '', phone: '', message: '' };

        if (!formData.name.trim()) {
            newErrors.name = 'Nome é obrigatório';
            isValid = false;
        }

        if (!formData.email.trim()) {
            newErrors.email = 'Email é obrigatório';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email inválido';
            isValid = false;
        }

        if (!formData.message.trim()) {
            newErrors.message = 'Mensagem é obrigatória';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                await sendMessage(formData);
                setFormSubmitted(true);
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
            } catch (error) {
                console.error('Erro ao enviar mensagem:', error);
                // Pode adicionar um tratamento de erro aqui, se desejar
            }
        }
    };

    return (
        <div className='contact-page'>
            <h1>Contato</h1>
            <p>Preencha o formulário abaixo para entrar em contato conosco. Responderemos o mais rápido possível.</p>

            {!formSubmitted ? (
                <form onSubmit={handleSubmit} className='contact-form'>
                    <label htmlFor='name'>Nome:</label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    {errors.name && <p className='error-message'>{errors.name}</p>}

                    <label htmlFor='email'>Email:</label>
                    <input
                        type='email'
                        id='email'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {errors.email && <p className='error-message'>{errors.email}</p>}

                    <label htmlFor='phone'>Telefone:</label>
                    <input
                        type='tel'
                        id='phone'
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                    />

                    <label htmlFor='message'>Mensagem:</label>
                    <textarea
                        id='message'
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                    {errors.message && <p className='error-message'>{errors.message}</p>}

                    <button type='submit' disabled={formSubmitted}>
                        {formSubmitted ? 'Mensagem Enviada' : 'Enviar'}
                    </button>
                </form>
            ) : (
                <p>Obrigado pelo seu contato! Em breve responderemos sua mensagem.</p>
            )}

            <div className='contact-info'>
                <h2>Ou entre em contato diretamente</h2>
                <p>Email: <a href='mailto:contato@meucarroja.com'>contato@meucarroja.com</a></p>
            </div>
        </div>
    );
};

export default ContatoPage;
