import React, { useState } from 'react';
import { FaCar, FaSearch } from 'react-icons/fa';
import './HeroSearch.css';
import InputSearch from '../InputSearch/InputSearch';

type HeroSearchProps = {
  onSubmitHandler: (searchTerm: string) => void;
};

const HeroSearch = ({ onSubmitHandler }: HeroSearchProps) => {
  return (
    <>
      <div className='hero-container'>
        <div className='blurred-bg'></div>
        <div className='hero-content'>
          <div className='logo-container'>
            <img
              src='/img/meucarroja-bg-white.png'
              alt='Meu Carro Já'
              className='logo'
            />
          </div>
          <h1 className='color-white'>Qual veículo você está procurando?</h1>
          <h4>Encontre o seu carro nas melhores revendas do interior do RS.</h4>
          <h5 className='color-white'>
            Novos veículos encontrados todos os dias!
          </h5>

          <InputSearch onSubmitHandler={onSubmitHandler} />
        </div>
      </div>
    </>
  );
};

export default HeroSearch;
