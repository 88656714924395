import React, { useState, useEffect } from 'react';
import { Car } from '../../types/Veiculo';
import { GiPositionMarker } from 'react-icons/gi';
import './Card.css';
import { FaHeart, FaRegCalendarAlt } from 'react-icons/fa';
import { FaHeartCirclePlus } from 'react-icons/fa6';
import { AiFillRightCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';

type CardProps = {
  car: Car;
};

const Card = ({ car }: CardProps) => {
  const navigate = useNavigate();
  const [preferred, setPreferred] = useState(false);

  const onClickHandler = (car: Car) => {
    navigate(`/veiculos/detalhes/${car.slug}`, { state: { car } });
  };

  useEffect(() => {
    const favoriteCars = JSON.parse(
      localStorage.getItem('favoriteCars') || '[]'
    );
    const isFavorite = favoriteCars
      .filter((car: Car) => car)
      .some((favCar: Car) => favCar?.slug === car?.slug);
    setPreferred(isFavorite);
  }, [car.slug]);

  const onChangePreferred = (event: React.MouseEvent) => {
    event.stopPropagation();

    const favoriteCars = JSON.parse(
      localStorage.getItem('favoriteCars') || '[]'
    );
    if (preferred) {
      // Remover carro dos favoritos
      const updatedFavorites = favoriteCars.filter(
        (favCar: Car) => favCar.slug !== car.slug
      );
      localStorage.setItem('favoriteCars', JSON.stringify(updatedFavorites));
    } else {
      // Adicionar carro aos favoritos
      favoriteCars.push(car);
      localStorage.setItem('favoriteCars', JSON.stringify(favoriteCars));
    }

    setPreferred((prevPreferred) => !prevPreferred);
  };

  return (
    <div className='card' onClick={() => onClickHandler(car)}>
      {!preferred && (
        <FaHeartCirclePlus
          className='icon-heart icon-disabled'
          // onClick={onChangePreferred}
        />
      )}
      {preferred && (
        <FaHeart
          className='icon-heart icon-enabled'
          // onClick={onChangePreferred}
        />
      )}
      <img src={car.image} alt={car.name} className='card-image' />
      <div className='card-content'>
        <h3 className='card-name'>{car.name}</h3>
        <div className='icon-container'>
          <FaRegCalendarAlt className='card-icon' size='10px' />
          <p className='card-year'>{car.year || '-- / --'}</p>
        </div>
        <div className='icon-container'>
          <GiPositionMarker className='card-icon' size='10px' />
          <p className='card-location'>
            {car.distanceKm
              ? car.distanceKm + 'Km'
              : car.shop.city + ' - ' + car.shop.state}
          </p>
        </div>
        <p className='card-price'>
          {car.price?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>

        <button className='card-button'>
          {/* <AiFillRightCircle size='24px' /> */}
          <MdArrowOutward />
        </button>
      </div>
    </div>
  );
};

export default Card;
