import React from 'react';
import './FirstSection.css';
import { IoPricetagsOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

const FirstSection = () => {
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate(`/veiculos/`);
  };

  return (
    <>
      <div className='container-fluid explore-wrapper'>
        <div className='container explore-container'>
          <div className='explore-content'>
            <h2>Sobre MeuCarroJá</h2>
            <h4>
              Explore por mais de 4000 mil veículos na regiao sul e encontre o
              carro dos seus sonhos perto de você!
            </h4>
            <p>
              Nós utilizamos motores de busca avançados para localizar os
              melhores veículos disponíveis na sua região. Aproveite a
              oportunidade de encontrar ofertas incríveis e veículos de
              qualidade a poucos cliques de distância.
            </p>
            <button
              style={{ width: '100%' }}
              className='button button-primary button-big'
              onClick={onButtonClick}
            >
              <FaSearch /> Encontrar Veículos
            </button>
          </div>
          <div className='explore-image'>
            <img
              className='image-one'
              src='/img/honda-civic.png'
              alt='honda-civic'
            />
            <div className='image-layer'>
              <img
                className='image-two'
                src='/img/hilux-terra.png'
                alt='hilux-terra'
              />
              <img
                className='image-two'
                src='/img/compass-road.png'
                alt='compass-road'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstSection;
