import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string

const containerStyle = {
    width: '100%',
    height: '400px'
};

// const defaultPosition = {
//     lat: -3.745,
//     lng: -38.523
// };

type MapsType = {
    position: {

        lat: number;
        lng: number;
    }
}

const Maps = ({ position }: MapsType) => {
    return (
        <>
            <LoadScript
                googleMapsApiKey={apiKey}
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={position}
                    zoom={13}
                >
                    {/* Adicione marcadores ou outros componentes do Google Maps aqui */}
                    <Marker position={position} />
                </GoogleMap>
            </LoadScript>
        </>
    );
};

export default Maps;
