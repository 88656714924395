import React from 'react';
import { Helmet } from 'react-helmet';

const HomePageMetaTag = () => {
  return (
    <Helmet>
      <title>MeuCarroJá - Buscador de Veículos</title>
      <meta
        name='description'
        content='Encontre o veículo ideal para você no MeuCarroJá. A melhor plataforma de busca de veículos do interior do Rio Grande do Sul.'
      />
      <meta
        name="keywords"
        content="revenda de veículos, carros à venda, carros usados, seminovos, Passo Fundo, Santa Rosa, Ijuí, Santo Ângelo, busca de carros, automóveis usados, veículos usados, Rio Grande do Sul, comprar carro, MeuCarroJá, mercado de veículos, carros baratos"
      />
      <meta name="theme-color" content="#000000" />

      <meta itemProp="name" content="MeuCarroJá - Buscador de Veículos" />
      <meta itemProp="description" content="Encontre o veículo ideal para você no MeuCarroJá. A melhor plataforma de busca de veículos no Rio Grande do Sul." />
      <meta itemProp="image" content="https://www.meucarroja.com/img/meucarroja-bg.png" />

      <meta property='og:title' content='MeuCarroJá - Buscador de Veículos' />
      <meta
        property='og:description'
        content='Encontre o veículo ideal para você no MeuCarroJá. A melhor plataforma de busca de veículos no Rio Grande do Sul.'
      />
      <meta
        property='og:image'
        content='https://www.meucarroja.com/img/meucarroja-bg.png'
      />
      <meta property='og:url' content='https://www.meucarroja.com' />
      <meta name='twitter:title' content='MeuCarroJá - Buscador de Veículos' />
      <meta
        name='twitter:description'
        content='Encontre o veículo ideal para você no MeuCarroJá. A melhor plataforma de busca de veículos no Rio Grande do Sul.'
      />
      <meta
        name='twitter:image'
        content='https://www.meucarroja.com/img/meucarroja-bg.png'
      />
      <meta name='twitter:card' content='summary_large_image' />
    </Helmet>
  );
};

export default HomePageMetaTag;
